import '../css/app.scss';

import Hamburger from './components/hamburger';
import Accordions from './components/accordions';
import Subnav from './components/subnav';
import Loader from './components/loader';
import Kachel from './components/kachel';
import KachelSlider from './components/kachelSlider';
import Slideshow from './components/slideshow';
import Dialog from './components/dialog';
import Rating from'./components/rating';
import Header from './components/header';
import Scroll from './components/scroll';


$(() => {

	//burger
	const $hamburger = $('[data-hamburger="el"]');

	new Hamburger($hamburger);

	//slieshow 
	const $slideshow = $('[data-slider="el"]');
	let slideshow = null
	$slideshow.each(function(i, element){
		slideshow = new Slideshow($(element));
	})
	

	//Loader
	const $loader = $('[data-loader="el"]');
	if($loader.length) {
		new Loader($loader, () => {
			if(slideshow) {
				setTimeout(()=> {
					slideshow.play();
				}, 1000);
			}
		});
	} else {
		$('html').addClass('ready');
	}

	//Accordion
	const $accordions = $('[data-accordions="el"]');
	$accordions.each(function (i, element) {
		new Accordions($(element));
	})


	const $subnavs = $('[data-subnav="el"]');
	$subnavs.each(function (i, element) {
		new Subnav($(element));
	});


	//Kachel Icons
	const $kacheln = $('[data-kachel="el"]');
	$kacheln.each(function (i, element) {
		new Kachel($(element));
	});

	//Kachel Slider
	const $kachelSlider = $('[data-kachelSlider="el"]');
	$kachelSlider.each(function(i, element){
		new KachelSlider($(element));
	});

	//Formular
	const $offertenTrigger = $('[data-dialog="trigger"], a[href="#offerte"]');

	$offertenTrigger.each((i, el) => {
		new Dialog($(el));
	});


	//Rating
	const $rating = $('[data-rating="el"]');
	$rating.each((i, el) => {
		new Rating($(el));
	});

	//Navhide
	const $header = $('[data-header="el"]');
	new Header($header);


	//ScrollTrigger	
	const $scrollImages = $('[data-scroll="image"]');
	const $fadeIn = $('[data-scroll="fade-in"]');
	new Scroll($fadeIn, $scrollImages);


	$.each($('main'), function() {
		if ($(this).children().last().hasClass('bg-secondary')) {
			$('footer').removeClass('bg-secondary');
		}
	});

	const $path = window.location.pathname;

	if($path.includes("kaminfegerarbeiten")){
		$('#kaminfegerarbeiten').prop("checked", true);
	}
	else if($path.includes("feuerungskontrolle")){
		$('#feuerungsarbeiten').prop("checked", true);
	}
});














class Subnav {

	stateClasses = {
		active: 'subnav--active'
	};


	constructor($el) {
		this.$el = $el;
        this.$subnav = $el.find('[data-subnav="subnav"]');
		this.init();

	}

	init = () => {

        this.$el.on('click', this.clickHandler);
	}

	clickHandler = () => {

        console.log("subnav");
		const {active} = this.stateClasses;

		this.$el.toggleClass(active);
		
		if(this.$el.hasClass(active)){
			this.$subnav.css('max-height', this.$subnav[0].scrollHeight + 'px');
		}
		else{
			this.$subnav.css('max-height', 0);
			
		}
	}
}

export default Subnav;



class Header {

    stateClasses = {
        hide: 'hide'
    };
    constructor($el) {
        this.$el = $el;
        this.$lastScrollTop = 0;

        this.init();
    }

    init = () => {
        $(window).on('scroll', this.hide)
    }

    hide = () => {
        var st = $(window).scrollTop();

            if (st > this.$lastScrollTop && st > 200) {
                this.$el.addClass(this.stateClasses.hide);
            } else {
                this.$el.removeClass(this.stateClasses.hide);
            }
            this.$lastScrollTop = st <= 0 ? 0 : st;
    }
}
export default Header;
import 'slick-carousel';

class Slideshow {

    stateClasses = {
        active: 'slick-custom-active'
    };
    
    constructor($el) {
        this.$el = $el;
        this.$dots = $('.dots');
        this.init();
    }

    init = () => {

        if (this.$el.length) {
            this.$el.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                dots: true,
                arrows: false,
                appendDots: this.$dots,
                speed: 2000,
                pauseOnHover: false,
                fade: true,
                adaptiveHeight: false,
                
            });


            this.$el.on('afterChange', (slick, currentSlide) => {
                const $slide = $(currentSlide.$slides[currentSlide.currentSlide]);
                 $slide.addClass(this.stateClasses.active);

            });

            this.$el.on('beforeChange', (slick, currentSlide, nextSlide) => {
                const $slide = $(currentSlide.$slides[currentSlide.currentSlide]);
                $slide.removeClass(this.stateClasses.active);

            });
        }
    }

    play = () => {
        const slickSlider = this.$el.slick('getSlick');
        $(slickSlider.$slides[0]).addClass(this.stateClasses.active);
        this.$el.slick('slickPlay');
    }
}
export default Slideshow;
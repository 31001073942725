import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

class Scroll{

    stateClasses = {
        active: 'in-view'
    };
    constructor($fadeIn, $scrollImages){
        this.$fadeIn = $fadeIn;
        this.$scrollImages = $scrollImages;
        this.init();
    }

    init = () => {

        this.fadeIn();
        this.imageScroll();    	        
    }

    fadeIn = () => {
        const $scrollAnimation = gsap.utils.toArray(this.$fadeIn);

	    $scrollAnimation.forEach((item, i) => {
	        ScrollTrigger.create({
                trigger: item,
                // animation: animate,
                toggleActions: 'play none none none',
                once: true,
                markers: false,
                onEnter: () => {
                    $(item).addClass(this.stateClasses.active);
                },
	        });
	    });
    }

    imageScroll = () => {

        this.$scrollImages.each((i, el) => {
            ScrollTrigger.create({
                trigger: el,
                start: 'top 80%',
                once: true,
                onEnter: () => {
                    $(el).addClass(this.stateClasses.active);
                },
                markers: false,
            })
        });

    }


}

export default Scroll;
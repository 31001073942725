import Accordion from "./accordion";

class Accordions {
    constructor($el) {
        this.$el = $el;
        this.$accordions = $el.find('[data-accordion="el"]');
        this.accordions = [];
        this.init();
    }

    init = () => {
        this.$accordions.each((i, el) => {
            this.accordions.push(new Accordion($(el), this.closeAll));
        })
    }

    closeAll = () => {
        this.accordions.forEach(accordion => {
            accordion.close();
        });
    }
}

export default Accordions;
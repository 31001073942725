class Hamburger {

	stateClasses = {
		active: 'nav--open'
	};

	isOpen = false;

	constructor($el) {
		this.$el = $el;
		this.$navigation = $('.navigation');
		this.$html = $('html');
		this.init();
	}

	init = () => {

		this.$el.on('click', this.clickHandler);
	}

	clickHandler = () => {
		console.log('Hamburger Clicked')

		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}


	}

	open = () => {
		this.$navigation.addClass(this.stateClasses.active);
		this.$html.css('overflow', "hidden");
		this.isOpen = true;
	}

	close = () => {
		this.$navigation.removeClass(this.stateClasses.active);
		this.$html.css('overflow', "scroll");
		this.isOpen = false;
	}


}

export default Hamburger;
import 'slick-carousel';

class KachelSlider{

    constructor($el){
        this.$el = $el;
        this.$next = $('[data-kachelSlider="next"]');
        this.$previous = $('[data-kachelSlider="previous"]');
        this.slidesToShow = this.$el.data('slides-to-show') ?? 3;
        this.mediumBreakPoint = this.mediumBreak();
        this.smallBreakPoint = this.smallBreak();
        this.init();
    }

    init = () =>{
        this.slick = this.$el.slick({
            // centerMode: true,
            slidesToShow: this.slidesToShow, 
            infinite: false,
            arrows: false,
            cssEase: 'linear',
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                    slidesToShow: this.mediumBreakPoint,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                    slidesToShow: this.smallBreakPoint,
                    }
                },
            ]
    
        });

        this.progress();

        $(this.$el).on('afterChange', this.afterChangeHanlder); 
        $(this.$next).on('click', () =>{
            this.$el.slick('slickNext');
        });
        $(this.$previous).on('click', () =>{
            this.$el.slick('slickPrev');
        });
    }

    smallBreak = () =>{
        if(this.slidesToShow >= 4 ){
            return 2.3
        }
        else{
            return 1.2
        }
    }

    mediumBreak = () =>{
        if(this.slidesToShow >= 4 ){
            return 3.4
        }
        else{
            return 2.3
        }
    }

    afterChangeHanlder = (event, slick, currentSlide, nextSlide) => {
        this.progress();
    }



    progress = () => {
        let { slick } = this.slick[0];
        let slidesToShow = slick.activeBreakpoint ? slick.breakpointSettings[slick.activeBreakpoint].slidesToShow : this.slidesToShow;
        let index = slick.currentSlide;
        let slideCount = slick.slideCount;
        let progress = (index + slidesToShow) / slideCount * 100;
        $('.progress-carousel__progress-bar').css('width', progress+"%");

        if(index === 0){
            this.$previous.addClass('d-none');
        }
        else{
            this.$previous.removeClass('d-none');
        }

        if(index + slidesToShow >= slideCount){
            this.$next.addClass('d-none');
        }
        else{
            this.$next.removeClass('d-none');
        }
       
    }
}

export default KachelSlider;
import Lottie from "lottie-web";

class Kachel {
    constructor($el) {
        this.$el = $el;
        this.$icon = $el.find('[data-kachel="icon"]');
        this.json = $el.data('json');
        this.init();
    }

    init = () => {

        this.lottie = Lottie.loadAnimation({
            container: this.$icon[0],
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: this.json,
        });
        

        const lottie = this.lottie;

        lottie.goToAndStop(1, true);

        this.$el.on('mouseenter', function(){
            if(lottie.isPaused) {
                lottie.goToAndPlay(1, true);
            }
        });
    }
}

export default Kachel;
import gsap from 'gsap';

class Loader {
    constructor($el, callback) {
        this.$el = $el;
        this.$image = $el.find('.loader__image');
        this.$html = $('html');
        this.callback = callback;

        if (!sessionStorage.getItem('loaderCompleted')) {
            this.init();
        } else {
            if (this.callback) {
                this.$html.addClass('ready');
                this.$el.remove();
                this.callback();
                
            }
        }
    }

    init = () => {
        var tl = gsap.timeline({ onComplete: this.onComplete });

        tl.to(this.$image, { y: -50, duration: 1 });
        tl.to(this.$image, { opacity: 1, duration: 1 }, "-=1");

        tl.to(this.$image, { y: -100, duration: 1, delay: 1 });
        tl.to(this.$image, { opacity: 0, duration: 1 }, "-=1");

        tl.to(this.$el, { opacity: 0, duration: 1 });
    }

    onComplete = () => {
        this.$html.addClass('ready');
        this.$el.remove();

        sessionStorage.setItem('loaderCompleted', 'true');

        if (this.callback) {
            this.callback();
        }
    }
}

export default Loader;


class Dialog {
    constructor($el) {
        this.$el = $el;
        this.$offerteDialog = $('dialog');
        this.$offertenClose = $('[data-dialog="close"]');
        this.system= $('.heizung input')

        this.id  = $el.data('id') ?? null;


        this.init();
    }

    init = () => {
        this.$el.on('click', (e) => {
            e.preventDefault();

            this.system.prop("checked", false);

            $(this.system).each((i, el) => {
                if ($(el).val() == this.id) {
                    $(el).prop("checked", true);
                }
            });

            this.$offerteDialog[0].showModal();

        });

        this.$offertenClose.on('click', () => {
            this.close();
        });

        this.$offerteDialog.on('click', (e) => {
            if (e.target == this.$offerteDialog[0]) {
                this.close();
            }
        });

    }

    close = () => {
        this.$offerteDialog[0].close();
    }
}

export default Dialog;
class Rating{
    constructor($el){
        this.$el = $el;
        this.score = $el.data('score');
        this.$indexes = $el.find('[data-rating="index"]');
        this.init();
    }

    init = () =>{
        this.$indexes.each((i, el) => {
            if(i + 1 <= this.score) {
                $(el).css('width', '100%');
            } else {
                let modulo = this.score - i;
                let percentage = modulo * 100;
                $(el).css('width', `${percentage}%`);
                return false;
            }
        });
    }
}
export default Rating
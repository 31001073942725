class Accordion {

    stateClasses = {
        active: 'accordion--active'
    };

    constructor($el, closeAllCallback = null) {
        this.$el = $el;
        this.$header = $el.find('[data-accordion="header"]');
        this.$panel = $el.find('[data-accordion="panel"]');
        this.closeAllCallback = closeAllCallback;
        this.init();
    }

    init = () => {
        this.$header.on('click', this.clickHandler);
    }


    clickHandler = () => {
        if (!this.$el.hasClass(this.stateClasses.active)) {
            this.open();
        } else {
            this.close();
        }
    }

    close = () => {
        this.$el.removeClass(this.stateClasses.active);
        this.$panel.css('max-height', 0);
    }

    open = () => {
        if (this.closeAllCallback) {
            this.closeAllCallback();
        }
        this.$el.addClass(this.stateClasses.active);
        this.$panel.css('max-height', this.$panel[0].scrollHeight + 20 +  'px');

    }
}

export default Accordion;


